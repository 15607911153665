<template>
    <validation-provider :name="name" :rules="rules" v-slot="validationContext">
        <b-form-group :label="label">
            <multiselect
                    v-model="selected"
                    :multiple="isMultiple"
                    :options="options"
                    :dosabled="disabled"
                    :placeholder="placeholder ? placeholder : 'Выберите из списка'"
                    :label="labelTitle"
                    :track-by="trackBy"
                    :custom-label="customLabel"
                    :class="validationContext.errors.length ? 'in-valid' : ''"
                    :openDirection="openDirection ? openDirection : 'bottom'"
            >
            </multiselect>
            <b-form-invalid-feedback :class="validationContext.errors.length ? 'd-block' : ''">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
    </validation-provider>
</template>

<script>
    export default {
        name: "ValidSelect",
        props: ['rules', 'name', 'value', 'label', 'vid', 'options', 'multiple', 'trackBy', 'labelTitle', 'placeholder', 'openDirection', 'disabled', 'customLabel'],
        data() {
            return {
                selected: this.value,
                isMultiple: typeof this.multiple !== 'undefined' ? this.multiple : true
            }
        },
      watch: {
            selected() {
                this.$emit('input', this.selected)
            }
        }
    }
</script>

<style lang="scss">
    .in-valid {
        .multiselect__tags {
            border-color: #dc3545;
        }
    }
</style>
