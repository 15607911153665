<template>
    <div v-if="course" class="animated fadeInRight">
        <BreadCrumbs :title="`Редактирование курса ${course.name}`" :items="[{title: 'Главная', url: '/'}, {title: 'Список курсов', url: '/admin'}]" />
        <b-modal :hide-footer="true" v-if="editTutor" id="edit-tutor" :title="editTutor.tutor.fullname">
            <validation-observer ref="observer" v-slot="{ passes }">
                <b-form @submit.stop.prevent="passes(onUpdateTutor)">
                    <file-upload class="text-left" :action-for-upload="null" v-model="editTutor.tutor.user.photo">
                        <img v-if="editTutor.tutor.user.photo" :src="editTutor.tutor.user.photo.thumbs.normal" class="rounded-circle circle-border m-b-md" alt="profile">
                        <img v-else width="150px" :src="'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==\n'" class="rounded-circle circle-border m-b-md" alt="profile">
                    </file-upload>
                    <ValidInput label="Имя" name="имя" :rules="{ required: true, min: 3 }" v-model="editTutor.tutor.user.firstName"/>
                    <ValidInput label="Отчество" name="отчество" :rules="{ required: true, min: 3 }" v-model="editTutor.tutor.user.middleName" />
                    <ValidInput label="Фамилия" name="фамилия" v-model="editTutor.tutor.user.lastName" :rules="{ required: true, min: 3 }"/>
                    <valid-summernote label="Резюме преподавателя" v-model="editTutor.tutor.degree"></valid-summernote>
                    <valid-checkbox vid="isAuthor" name="Автор курса" v-model="editTutor.isAuthor"></valid-checkbox>
                    <hr class="hr-line-dashed"/>
                    <b-btn type="submit" class="pull-right" size="sm" variant="primary">Сохранить</b-btn>
                </b-form>
            </validation-observer>
        </b-modal>
        <b-modal id="add-tutor"
                 ref="modal"
                 button-size="sm"
                 title="Добавление преподавателя"
                 :hide-footer="true"
        >
            <validation-observer ref="observer" v-slot="{ passes }">
                <b-form @submit.stop.prevent="passes(onAddTutor)">
                    <b-form-group label="Фото пользователя">
                        <div class="profile-image">
                            <file-upload :action-for-upload="null" v-model="addTutor.photo">
                                <img v-if="addTutor.photo" :src="addTutor.photo.thumbs.normal" class="rounded-circle circle-border m-b-md" alt="profile">
                                <img v-else :src="'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==\n'" class="rounded-circle circle-border m-b-md" alt="profile">
                            </file-upload>
                        </div>
                    </b-form-group>
                    <!--<valid-checkbox v-model="addTutor.withoutUser" vid="withoutUser" name="Без пользователя" />-->
                    <template v-if="!addTutor.withoutUser">
                        <hr class="hr-line-dashed" />
                        <ValidInput label="Логин (email)" name="логин" :rules="{ required: true, email:true }" v-model="addTutor.username"/>
                        <ValidInput label="Пароль" name="пароль" :rules="{ required: true, min: 6 }" v-model="addTutor.password"/>
                        <hr class="hr-line-dashed" />
                    </template>
                    <ValidInput label="Имя" name="имя" :rules="{ required: true, min: 3 }" v-model="addTutor.firstName"/>
                    <ValidInput label="Фамилия" name="фамилия" v-model="addTutor.lastName" :rules="{ required: true, min: 3 }"/>
                    <valid-summernote label="Резюме преподавателя" v-model="addTutor.degree"></valid-summernote>
                    <valid-checkbox vid="isAuthor" name="Автор курса" v-model="addTutor.isAuthor"></valid-checkbox>
                    <b-button type="submit" size="sm" variant="primary">Сохранить</b-button>
                </b-form>
            </validation-observer>
        </b-modal>
        <div class="wrapper wrapper-content animated fadeIn">
            <div class="tabs-container">
                <b-tabs v-model="tabIndex">
                    <b-tab title="О курсе">
                        <div class="panel-body">
                            <validation-observer ref="observer" v-slot="{ passes }">
                                <b-form @submit.stop.prevent="onSubmit(passes)">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="ibox">
                                                <div class="ibox-content">
                                                    <fieldset>
                                                      <div class="row">
                                                        <div class="col-md-4">
                                                          <ValidInput name="Название курса" label="Название курса" :rules="{ required: true }"  v-model="course.name"/>
                                                        </div>
                                                        <div class="col-md-4">
                                                          <label>Категории</label>
                                                          <multiselect
                                                              v-model="course.category"
                                                              :multiple="false"
                                                              :searchable="true"
                                                              :options="categories"
                                                              track-by="id"
                                                              placeholder="Начните вводить название категории"
                                                              label="title"
                                                              :custom-label="opt => opt.title"
                                                              openDirection="bottom"
                                                              :loading="isCodiLoader"
                                                              @search-change="searchCat"
                                                          ></multiselect>
                                                        </div>
                                                        <div class="col-md-4">
                                                          <label>Элементы кодификатора (компетенции)</label>
                                                          <multiselect
                                                              v-model="course.codifiers"
                                                              :multiple="true"
                                                              :searchable="true"
                                                              :options="codifiers"
                                                              track-by="id"
                                                              placeholder="Начните вводить название кодификатора"
                                                              label="title"
                                                              :custom-label="opt => opt.title"
                                                              openDirection="bottom"
                                                              :loading="isCodiLoader"
                                                              @search-change="searchCodi"
                                                          ></multiselect>
                                                        </div>
                                                        <div class="col-md-2">
                                                          <label>Длительность</label>
                                                          <ValidInput name="Длительность курса в часах" v-model.number="course.duration"/>
                                                        </div>
                                                        <div class="col-md-3">
                                                          <ValidInput name="Стоимость курса"
                                                                      label="Стоимость курса"
                                                                      type="number"
                                                                      :step="1"
                                                                      :rules="{ required: true, numeric: true, min_value: 0 }"
                                                                      v-model="course.cost"/>
                                                        </div>
                                                        <div class="col-md-2">
                                                          <label>Поддержка</label>
                                                          <switcher color="#1c84c6" v-model="course.isSupport"/>
                                                          <small class="muted">Будут доступны чаты под курсами</small>
                                                        </div>
                                                      </div>
                                                        <div class="hr-line-dashed"></div>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Преподаватели:">
                                                                    <div v-if="course.courseTutors" class="animated fadeInRight">
                                                                        <div class="clients-list">
                                                                            <b-table striped hover bordered :fields="fields" thead-class="hidden_header" :items="course.courseTutors">
                                                                                <template v-slot:cell(photo)="data">
                                                                                    <img v-if="data.item.tutor.photo && data.item.tutor.photo.thumbs" alt="image" :src="data.item.tutor.photo.thumbs.smaller">
                                                                                </template>
                                                                                <template v-slot:cell(contactType)>
                                                                                    <i class="fa fa-envelope"></i>
                                                                                </template>
                                                                                <template v-slot:cell(actions)="data">
                                                                                    <b-btn-group>
                                                                                        <b-button v-b-tooltip title="Редактировать" variant="info" size="xs" @click="editUser(data.item)" class="text-white"><i class="fa fa-edit"></i></b-button>
                                                                                        <b-button v-b-tooltip title="Удалить" variant="danger" size="xs" @click="deleteUser(data.item)" class="text-white"><i class="fa fa-close"></i></b-button>
                                                                                    </b-btn-group>
                                                                                </template>
                                                                                <template v-slot:cell(fullname)="data">
                                                                                    <a class="client-link">{{data.item.tutor.fullname}}</a>
                                                                                </template>
                                                                            </b-table>
                                                                        </div>
                                                                    </div>
                                                                    <div class="text-muted">
                                                                        Выберите справа преподавателей, которые будут участвовать в курсе или добавьте новых
                                                                    </div>
                                                                </b-form-group>
                                                            </b-col>
                                                            <b-col>
                                                                <div v-if="users" class="ibox ">
                                                                    <div class="ibox-title">
                                                                        <b-btn v-b-modal.add-tutor variant="primary" class="pull-right" size="xs"><i class="fa fa-plus"></i> Добавить нового</b-btn>
                                                                        <h5 style="font-weight: 600;">Поиск преподавателя</h5>
                                                                    </div>
                                                                    <div class="ibox-content">
                                                                        <div class="input-group">
                                                                            <input type="text" v-model="search" placeholder="Введите ФИО преподавателя" class="input form-control">
                                                                            <span class="input-group-append">
                                                                                <button type="button" class="btn btn btn-primary">
                                                                                    <i class="fa fa-search"></i> Найти
                                                                                </button>
                                                                            </span>
                                                                        </div>
                                                                        <div class="row wrapper white-bg page-heading"></div>
                                                                        <b-row>
                                                                            <div v-for="(user, index) in filteredList" :key="index" class="col-lg-6 animated fadeIn">
                                                                                <div class="contact-box center-version">

                                                                                    <a @click="addUser(user)" href="#">

                                                                                        <img v-if="user.photo" alt="image" class="rounded-circle" :src="user.photo.thumbs.big">


                                                                                        <h3 class="m-b-xs"><strong>{{user.fullname}}</strong></h3>

                                                                                        <div class="font-bold">Преподаватель</div>
                                                                                        <address v-if="user.company" class="m-t-md">
                                                                                            <strong>{{ user.company.shortName }}</strong><br>
                                                                                        </address>

                                                                                    </a>
                                                                                    <div class="contact-box-footer">
                                                                                        <div class="m-t-xs btn-group">
                                                                                            <a href="javascript:void(0)" @click="addUser(user)"  class="btn btn-xs btn-primary"><i class="fa fa-user-plus"></i> Добавить </a>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </b-row>
                                                                    </div>
                                                                </div>
                                                            </b-col>
                                                        </b-row>
                                                        <div class="hr-line-dashed"></div>
                                                        <b-row>
                                                          <b-col>
                                                            <valid-date label="Дата завершения (генерации сертификатов)" v-model="course.realStartDateTime"/>
                                                          </b-col>
                                                          <b-col>
                                                            <valid-textarea label="Короткое описание" name="короткое описание" v-model="course.shortDescription" />
                                                          </b-col>
                                                        </b-row>
                                                        <div class="hr-line-dashed"></div>
                                                        <ValidSummernote label="Описание" name="описание" v-model="course.description" />
                                                        <div class="hr-line-dashed"></div>
                                                            <div class="form-group row">
                                                                <div class="col-sm-3">
                                                                    <label class="col-sm-12 col-form-label">Превью (фото):</label>
                                                                    <AvatarUpload :media="course.cover"></AvatarUpload>
                                                                </div>
                                                                <div class="col-sm-3">
                                                                    <label class="col-sm-12 col-form-label">Превью (видео):</label>
                                                                    <div class="file-box">
                                                                        <div class="file">
                                                                            <el-upload
                                                                                    :action="axios.baseURL() + 'media_objects'"
                                                                                    :headers="{Authorization: 'Bearer ' + token}"
                                                                                    :on-success="videoUpload"
                                                                                    accept="video/mp4"
                                                                                    class="upload-video"
                                                                            >
                                                                                <div class="icon">
                                                                                    <i class="img-fluid fa fa-film"></i>
                                                                                </div>
                                                                                <div class="file-name">
                                                                                    Загрузить файл
                                                                                </div>
                                                                            </el-upload>
                                                                            <div v-if="course.promo" class="file-name">
                                                                                <EncoderProcess :media="course.promo"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6">
                                                                   <b-row>
                                                                       <b-col>
                                                                           <valid-checkbox hint="Курс будет скрыт из каталога, но доступен прямой ссылке" vid="isClosed" name="Скрытый курс" v-model="course.isClosed" />
                                                                       </b-col>
                                                                       <b-col>
                                                                           <valid-checkbox  hint="Курс будет доступен для обучения" name="Курс активен" vid="isActive" v-model="course.isActive"/>
                                                                       </b-col>
                                                                       <b-col>
                                                                           <valid-checkbox name="Оффлайн" vid="isOffline" v-model="course.isOffline"/>
                                                                       </b-col>
                                                                   </b-row>
                                                                </div>
                                                            </div>
                                                    </fieldset>

                                                </div>
                                                <div class="ibox-footer border d-block">
                                                    <b-row>
                                                        <b-col v-if="course.isOffline">
                                                            <course-schedule :course="course"
                                                                             :full-mode="true"
                                                                             style-class="btn btn-block btn-warning"
                                                            />
                                                        </b-col>
                                                        <b-col>
                                                            <router-link class="btn btn-success btn-sm btn-block" :to="`/course/${course.id}`" target="_blank"><i class="fa fa-eye"></i> Предпросмотр курса</router-link>
                                                        </b-col>
                                                        <b-col>
                                                            <button type="submit" class="btn btn-primary btn-block"><i class="fa fa-save"></i> Сохранить</button>
                                                        </b-col>
                                                    </b-row>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </b-form>
                            </validation-observer>
                        </div>
                    </b-tab>
                    <b-tab v-for="(module, index) in course.modules" :key="index" :title="`Модуль ${index +1}`">
                        <div class="panel-body">
                            <ModuleCreate :module="module" :tutors="course.courseTutors" :course="course" />
                        </div>
                    </b-tab>
                    <b-tab>
                        <template v-slot:title>
                            <i class="fa fa-user-plus"></i> Добавить студентов
                        </template>
                        <div class="panel-body">
                            <EnrollList :course="course"/>
                        </div>
                    </b-tab>
                    <template v-slot:tabs-end>
                        <b-nav-item @click="addModule(course.modules.length)" href="#">
                            <i class="fa fa-plus"></i> Добавить модуль
                        </b-nav-item>
                    </template>
                </b-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import BreadCrumbs from "../../components/BreadCrumbs";
    import ModuleCreate from "./ModuleCreate";
    import AvatarUpload from "./AvatarUpload";
    import {eventBus} from "../../main";
    import EncoderProcess from "./EncoderProcess";
    import ValidSummernote from "../../components/Validation/ValidSummernote";
    import EnrollList from "./EnrollList";
    import ValidInput from "../../components/Validation/ValidInput";
    import ValidTextarea from "../../components/Validation/ValidTextarea";
    import ValidCheckbox from "../../components/Validation/ValidCheckbox";
    import CourseSchedule from "../../components/Schedule/CourseSchedule";
    import FileUpload from "./FileUpload";
    import ValidDate from "../../components/Validation/ValidDate";
    import Switcher from "./Switcher";
    import ApiService from "../../services/api.service";
    import {TokenService} from "../../services/token.service";
    export default {
        name: "CourseEdit",
        components: {
          Switcher,
          ValidDate,
            FileUpload,
            CourseSchedule,
            ValidCheckbox,
            ValidTextarea,
            ValidInput, EnrollList, ValidSummernote, EncoderProcess, AvatarUpload, ModuleCreate, BreadCrumbs},
        data() {
            return {
              token: TokenService.getToken(),
              categories: [],
              axios: ApiService,
              imageUrl: null,
              isCodiLoader: false,
                range: null,
                course: null,
                users: null,
                tabIndex: 0,
                search: '',
                editTutor: null,
                addTutor: {},
              codifiers: [],
                fields: [
                    {
                        key: 'photo',
                        tdClass: 'client-avatar'
                    },
                    {
                        key: 'tutor.fullname'
                    },
                    {
                        key: 'contactType',
                        tdClass: 'contact-type'
                    },
                    {
                        key: 'tutor.username'
                    },
                    {
                        key: 'tutor.roleRu'
                    },
                    {
                        key: 'actions'
                    }
                ],
            }
        },
        mounted() {
            this.tabIndex = this.$route.params.index ? parseInt(this.$route.params.index) : 0;
            eventBus.$on('avatar-upload', res => {
                this.course.cover = res
                this.putAction(this.course);
            })
            eventBus.$on('module-delete', module => {
                let index = this.course.modules.indexOf(module);
                this.course.modules.splice(index, 1);
                this.tabIndex = 0;
            });
            ApiService
                .get(`courses/${this.$route.params.id}`, {
                    params: {
                        edit: true
                    }
                })
                .then(res => {
                    if (res.status === 404) {
                        this.$toasted.global.appError('Курс не найден');
                        this.$router.push('/catalog');

                        return;
                    }

                    this.course = res.data;
                    this.axios.get('tutors/touch').then(res => {
                        this.users = res.data;
                        if(this.course.courseTutors && this.course.courseTutors.length) {
                            this.users = this.users.filter(userTutor => {
                                return this.course.courseTutors.find(tutor => {
                                    tutor.tutor.userId !== userTutor.userId
                                });
                            });
                        }
                    })
                });
        },
        methods: {
          searchCategories(query) {
            if(query !== '') {
              this.isCodiLoader = true;
              this.axios.get(`categories`, {
                params: {
                  q: query,
                  hideLoader: true
                }
              }).then(res => {
                this.isCodiLoader = false
                if(res.status === 200) {
                  this.codifiers = res.data;
                  console.log(this.codifiers);
                }
              });
            }
          },
          searchCodi(query) {
            if(query !== '') {
              this.isCodiLoader = true;
              this.axios.get(`codifiers`, {
                params: {
                  q: query,
                  hideLoader: true
                }
              }).then(res => {
                this.isCodiLoader = false
                if(res.status === 200) {
                  this.codifiers = res.data;
                  console.log(this.codifiers);
                }
              });
            }
          },
          searchCat(query) {
            if(query !== '') {
              this.isCodiLoader = true;
              this.axios.get(`categories`, {
                params: {
                  q: query,
                  hideLoader: true
                }
              }).then(res => {
                this.isCodiLoader = false
                if(res.status === 200) {
                  this.categories = res.data;
                  console.log(this.categories);
                }
              });
            }
          },
            putAction(course) {
                if(course.promo && course.promo.encoderTasks) {
                    delete course.promo.encoderTasks
                }
                this.axios.put(`courses/${this.course.id}`, course).then((res) => {
                  if(res.status === 200) {
                    this.course = res.data;
                    this.$toasted.global.appSuccess()
                  }
                })
            },
            videoUpload(res){
              const courseData = {
                ...this.course,
                promo: res
              }
                this.putAction(courseData)
            },
            onSubmit() {
                const courseData = {
                    ...this.course,
                    cost: Number(this.course.cost)
                }

                this.putAction(courseData);
            },
            addModule(index) {
                index++
                this.axios
                    .postJSON('modules', {
                        name: 'Модуль ' + parseInt(index),
                        course: `/courses/${this.$route.params.id}`
                    })
                    .then(res => {
                        if(res.status === 201) {
                            this.course.modules.push(res.data)
                            this.$router.push('/admin/course/' + this.$route.params.id + '/module/' + index)
                            setTimeout(() => {
                                this.tabIndex = this.course.modules.length;
                            }, 500)
                        }
                    })
            },
            addUser(user) {
                this.course.courseTutors.unshift({tutor: user})
                let index = this.users.indexOf(user.tutor)
                this.users.splice(index, 1)
                this.axios
                    .put(`courses/${this.course.id}`, {courseTutors: this.course.courseTutors})
                    .then(() => {
                        this.$toasted.global.appSuccess('Преподаватель успешно добавлен')
                    })
            },
            onAddTutor() {
                this.addTutor.courseId = this.course.id;
                this.axios
                    .postJSON(`tutors`, this.addTutor).then(() => {
                    this.$router.go(this.$router.currentRoute);
                });
            },
            deleteUser(user) {
                this.users.unshift(user.tutor)
                let index = this.course.courseTutors.indexOf(user)
                this.course.courseTutors.splice(index, 1)
            },
            editUser(tutor) {
                this.editTutor = tutor;
                this.$bvModal.show('edit-tutor');
            },
            onUpdateTutor() {
                this.axios.put(`course_tutors/${this.editTutor.id}`, this.editTutor).then(() => {
                    this.$bvModal.hide('edit-tutor');
                    this.editTutor = null;
                })
            }
        },
        watch: {
            tabIndex: function (val) {
                if(!val) {
                    this.$router.push('/admin/course/' + this.$route.params.id)
                } else if(this.course && (val <= this.course.modules.length)) {
                    this.$router.push('/admin/course/' + this.$route.params.id + '/module/' + val)
                } else if(this.course && ((val + 1) > this.course.modules.length)) {
                    this.$router.push('/admin/course/' + this.$route.params.id + '/students')
                }
            }
        },
        computed: {
            filteredList() {
                return this.users ? this.users.filter(user => {
                    return user.fullname.toLowerCase().includes(this.search.toLowerCase()) || user.fullname.toLowerCase().includes(this.search.toLowerCase())
                }) : []
            },
        }
    }
</script>

<style>
    .upload-video .el-upload {
        display: block;
    }
    .el-date-editor {
        width: 100% !important;
    }
</style>
