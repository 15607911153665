<template>
    <div class="wrapper wrapper-content">
        <b-row v-if="!selectedUser">
            <b-col md="12">
                <div class="ibox">
                    <div class="ibox-title">Поиск студента</div>
                    <div class="ibox-content">
                        <form @submit.stop.prevent="load()" class="input-group">
                            <input type="text" v-model="search" placeholder="Поиск ФИО" class="input form-control">
                            <span class="input-group-append">
                                <button type="submit" class="btn btn btn-primary"> <i class="fa fa-search"></i> Найти</button>
                            </span>
                        </form>
                        <div class="row wrapper white-bg page-heading"></div>
                        <b-row>
                            <div v-for="(enrollment, index) in enrollments" :key="index" class="col-lg-4 animated fadeIn">
                                <div class="contact-box center-version">
                                    <a @click="addResult(enrollment.user)">
                                        <b-badge class="pull-right" :variant="getVariant(enrollment.elementData.status)">
                                            {{ enrollment.elementData.statusRu }}
                                        </b-badge>
                                        <img
                                            v-if="enrollment.user.photo && enrollment.user.photo.thumbs"
                                            :src="enrollment.user.photo.thumbs.big"
                                            alt="image"
                                            class="rounded-circle"/>
                                        <h3 class="m-b-xs">
                                            <strong>{{ enrollment.user.fullname }}</strong>
                                        </h3>
                                        <address v-if="enrollment.user.company" class="m-t-md">
                                            <strong>{{ enrollment.user.company.shortName }}</strong><br>
                                        </address>

                                    </a>
                                    <div class="contact-box-footer">
                                        <div class="m-t-xs btn-group">
                                            <a @click="addResult(enrollment.user)"
                                               class="btn btn-xs btn-primary text-white">
                                                Добавить результат
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="enrollments.length === 0" class="full-width text-center">
                                Студентов не найдено
                            </div>
                        </b-row>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row v-if="selectedUser">
            <h2>
                Студент: {{ selectedUser.fullname }} / (id: {{ selectedUser.id }})
                <b-button @click="selectedUser = null" size="xs" variant="primary">К списку студентов</b-button>
            </h2>
            <test-passage
                :test-id="testId"
                :user-id="selectedUser.id"
                :methodist-mode="true"
                class="full-width"/>
        </b-row>
    </div>
</template>

<script>
    import ApiService from "../../services/api.service";
    import TestPassage from "./TestPassage";
    export default {
        name: "TestUserList",
        components: {TestPassage},
        props: {
            courseId: Number,
            moduleElementId: Number,
            testId: Number,
        },
        data() {
            return {
                selectedUser: null,
                search: null,
                enrollments: [],
            }
        },
        mounted() {
            this.load();
        },
        methods: {
            load() {
                let extraData = {};
                if (this.search) {
                    extraData = {
                        ...extraData,
                        userSearch: this.search,
                    };
                }

                if (this.moduleElementId) {
                    extraData = {
                        ...extraData,
                        withModuleElement: this.moduleElementId,
                    };
                }

                ApiService.get(`enrollments`, {
                    params: {
                        ...extraData,
                        course: this.courseId,
                        isActive: true
                    }
                }).then(res => {
                    this.enrollments = res.data.data
                })
            },
            addResult(user) {
                this.selectedUser = user;
            },
            convertElementStatus(status) {
                switch (status) {
                    case 'active':
                        return 'доступен';
                    case 'success':
                        return 'завершен';
                    case 'failed':
                        return 'провален';
                }

                return status;
            }
        }
    }
</script>

<style scoped>

</style>
