<template>
    <validation-provider :name="name" :vid="vid" :rules="rules" v-slot="validationContext">
        <b-form-group :label="label">
            <el-date-picker
                    v-model="range"
                    type="datetimerange"
                    format="dd.MM.yyyy HH:mm:ss"
                    value-format="yyyy-MM-ddTHH:mm:ss"
                    range-separator="до"
                    start-placeholder="Дата старта"
                    end-placeholder="Дата завершения">
            </el-date-picker>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
    </validation-provider>
</template>

<script>
    export default {
        name: "ValidDateRange",
        props: ['value', 'label', 'name', 'vid', 'rules'],
        data() {
            return {
                range: this.value
            }
        },
        watch: {
            range(val, oldVal) {
                if(val !== oldVal) {
                    this.$emit('input', val)
                }
            }
        }
    }
</script>

<style scoped>

</style>
