<template>
    <div v-if="users" class="wrapper wrapper-content animated fadeInRight">
        <div class="wrapper wrapper-content">
            <b-row>
                <b-col md="6">
                    <div class="ibox">
                        <div class="ibox-title">Поиск студента</div>
                        <div class="ibox-content">
                            <div class="input-group">
                                <input type="text" v-model="search" placeholder="Введите Email" class="input form-control">
                                <span class="input-group-append">
                            <button type="button" class="btn btn btn-primary"> <i class="fa fa-envelope-o"></i> Отправить инвайт</button>
                        </span>
                            </div>
                            <div class="row wrapper white-bg page-heading"></div>
                            <b-row>
                                <div v-for="(user, index) in filteredList" :key="index" class="col-lg-4 animated fadeIn">
                                    <div class="contact-box center-version">

                                        <a @click="addUser(user)">

                                            <img v-if="user.photo && user.photo.thumbs" alt="image" class="rounded-circle" :src="user.photo.thumbs.big">


                                            <h3 class="m-b-xs"><strong>{{user.fullname}}</strong></h3>

                                            <div class="font-bold">{{user.roleRu}}</div>
                                            <address v-if="user.company" class="m-t-md">
                                                <strong>{{ user.company.shortName }}</strong><br>
                                            </address>

                                        </a>
                                        <div class="contact-box-footer">
                                            <div class="m-t-xs btn-group">
                                                <a @click="addUser(user)" class="btn btn-xs btn-primary text-white"><i class="fa fa-user-plus"></i> Добавить </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-row>
                        </div>
                    </div>
                </b-col>
                <b-col md="6">
                    <div class="ibox">
                        <div class="ibox-title">Поиск участника курса</div>
                        <div class="ibox-content">
                            <div class="input-group">
                                <input type="text" v-model="searchDelete" placeholder="Введите ФИО" class="input form-control">
                                <span class="input-group-append">
                            <button type="button" class="btn btn btn-info"> <i class="fa fa-search"></i> Найти</button>
                        </span>
                            </div>
                            <div class="row wrapper white-bg page-heading"></div>
                            <b-row>
                                <div v-for="(user, index) in filteredDeleteList" :key="index" class="col-lg-4 animated fadeIn">
                                    <div class="contact-box center-version">

                                        <a @click="deleteUser(user)" >

                                            <img v-if="user.photo && user.photo.thumbs" alt="image" class="rounded-circle" :src="user.photo.thumbs.big">


                                            <h3 class="m-b-xs"><strong>{{user.fullname}}</strong></h3>

                                            <div class="font-bold">{{user.roleRu}}</div>
                                            <address v-if="user.company" class="m-t-md">
                                                <strong>{{ user.company.shortName }}</strong><br>
                                            </address>

                                        </a>
                                        <div class="contact-box-footer">
                                            <div class="m-t-xs btn-group">
                                                <a @click="deleteUser(user)"  class="btn btn-xs btn-danger text-white"><i class="fa fa-user-times"></i> Удалить </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-row>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    import ApiService from "../../services/api.service";
    export default {
        name: "EnrollList",
        props: ['course'],
        data() {
            return {
              users: null,
              enrollments: [],
              addUsers: [],
              form: {},
              search: '',
              searchDelete: '',
              axios: ApiService
            }
        },
        mounted() {
            this.axios.get('users').then(res => {
                this.users = res.data
                this.axios.get(`enrollments`, {
                    params: {
                        course: this.course.id,
                        isActive: true
                    }
                }).then(res => {
                    this.enrollments = res.data.data
                    this.enrollments.forEach(enroll => {
                        this.addUsers.push(enroll.user);
                        let index = this.users.findIndex(user => user.id === enroll.user.id)
                        this.users.splice(index, 1)
                    })
                })
            })
        },
        methods: {
            addUser(user) {
                this.axios.postJSON(`/enrollments/${this.course.id}/${user.id}`, {
                    isActive: true
                }).then(() => {
                    this.addUsers.unshift(user)
                    let index = this.users.indexOf(user)
                    this.users.splice(index, 1)
                })
            },
            deleteUser(user) {
                this.axios.postJSON(`/enrollments/${this.course.id}/${user.id}`, {
                    isActive: false
                }).then(() => {
                    this.users.unshift(user)
                    let index = this.addUsers.indexOf(user)
                    this.addUsers.splice(index, 1)
                })
            }
        },
        computed: {
            filteredList() {
                return this.users ? this.users.filter(user => {
                    return user.username.toLowerCase().includes(this.search.toLowerCase()) || user.fullname.toLowerCase().includes(this.search.toLowerCase())
                }) : []
            },
            filteredDeleteList() {
                return this.addUsers.length ? this.addUsers.filter(user => {
                    return user.username.toLowerCase().includes(this.searchDelete.toLowerCase()) || user.fullname.toLowerCase().includes(this.searchDelete.toLowerCase())
                }) : []
            },
        }
    }
</script>

<style>
    .contact-box a:hover {
        color: inherit;
    }
    .el-autocomplete {
        display: block;
        width: 100%;
    }
    .contact-box-footer.full {
        margin: 0 -20px;
        padding-bottom: 0;
    }
</style>
