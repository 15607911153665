<template>
    <div class="wrapper wrapper-content">
        <b-row>
            <b-col md="12">
                <div class="ibox">
                    <div class="ibox-title">Поиск студента</div>
                    <div class="ibox-content">
                        <form class="input-group">
                            <input type="text" v-model="search" placeholder="Поиск ФИО" class="input form-control">
                            <span class="input-group-append">
                                <button type="button" class="btn btn btn-primary"> <i class="fa fa-search"></i> Найти</button>
                            </span>
                        </form>
                        <div class="row wrapper white-bg page-heading"></div>
                        <b-row>
                            <div v-for="(enrollment, index) in enrollments" :key="index" class="col-lg-4 animated fadeIn">
                                <div class="contact-box center-version">
                                    <a @click="addResult(enrollment.elementData)">
                                        <b-badge class="pull-right" :variant="getVariant(enrollment.elementData.status)">
                                            {{ enrollment.elementData.statusRu }}
                                        </b-badge>
                                        <img
                                                v-if="enrollment.user.photo && enrollment.user.photo.thumbs"
                                                :src="enrollment.user.photo.thumbs.big"
                                                alt="image"
                                                class="rounded-circle"/>
                                        <h3 class="m-b-xs">
                                            <strong>{{ enrollment.user.fullname }}</strong>
                                        </h3>

                                        <div class="font-bold">{{ enrollment.user.roleRu }}</div>
                                        <address v-if="enrollment.user.company" class="m-t-md">
                                            <strong>{{ enrollment.user.company.shortName }}</strong><br>
                                        </address>

                                    </a>
                                    <div class="contact-box-footer">
                                        <div class="m-t-xs btn-group">
                                            <a v-if="enrollment.elementData.status !== 'success'" @click="() => {addResult(enrollment.elementData, 'success'); enrollment.elementData.status = 'success'; enrollment.elementData.statusRu = 'Успешно пройден'}"
                                               class="btn btn-xs btn-primary text-white">
                                                Отметить посещение
                                            </a>
                                            <a v-else @click="() => {addResult(enrollment.elementData, 'active'); enrollment.elementData.status = 'active'; enrollment.elementData.statusRu = 'Активен'}"
                                               class="btn btn-xs btn-danger text-white">
                                                Отменить посещение
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-row>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import ApiService from "../../services/api.service";
    export default {
        name: "VideoElementUserList",
        props: {
            courseId: Number,
            elementId: Number
        },
        data() {
            return {
                search: null,
                enrollments: [],
              axios: ApiService
            }
        },
        mounted() {
            this.load();
        },
        methods: {
            load() {
                this.axios.get(`enrollments`, {
                    params: {
                        course: this.courseId,
                        isActive: true,
                        withModuleElement: this.elementId
                    }
                }).then(res => {
                    this.enrollments = res.data.data
                })
            },
            addResult(elementData, status = 'success') {
                this.axios.put(`element_results/${elementData.id}`, {status: status});
            }
        }
    }
</script>

<style scoped>

</style>
