<template>
    <a target="_blank" :href="`${axios.baseURL()}pdf/schedule-module/${module.id}?access_token=${token}`" v-b-tooltip :title="title" :class="styleClass">
        <i class="fa fa-print"></i>
        <template v-if="fullMode">
            {{title}}
        </template>
    </a>
</template>

<script>
    import ApiService from "../../services/api.service";
    import {TokenService} from "../../services/token.service";
    export default {
        name: "ModuleSchedule",
        props: {
            module: {
                type: Object
            },
            fullMode: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: 'Скачать расписание'
            },
            styleClass: {
                type: String,
                default: 'btn btn-white btn-sm'
            }
        },
      data() {
          return {
            axios: ApiService,
            token: TokenService.getToken()
          }
      }
    }
</script>

<style scoped>

</style>
